.root {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: var(--gap-small);
  }
  /* color: blue; */
}

.center {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
