.root {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    --size: 18px;
    height: var(--size);
  }

  & > * + * {
    margin-left: spacing(1);
  }
}
