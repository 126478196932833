.root {
  min-height: 100vh;
  padding-bottom: var(--gap-big);

  & > * + * {
    margin-top: var(--gap-big);
  }
}

.paddingTop {
  padding-top: var(--gap-small);
}

.spacing-extra {
  & > * + * {
    margin-top: var(--gap-extra-big);
  }
}
