.root {
  width: 100%;
  min-width: var(--content-min-width);
  max-width: var(--content-max-width);
  margin-right: auto;
  margin-left: auto;
}

.fullHeight {
  height: 100%;
  /* min-height: calc(100vh - var(--navHeight)); */
}

.padding-regular {
  padding: 0 var(--side-margin);

  @media ($tablet-landscape-down) {
    padding: 0 var(--side-margin-small);
  }
}

.full {
  max-width: auto;
}

.article {
  max-width: var(--article-max-width);
}

:global(.container) .no-nested-padding {
  padding-right: 0;
  padding-left: 0;
}
