.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-backdrop);
  background: rgba(0, 0, 0, 0.65);
  /* Blurring like this won't work everywhere but is a nice touch where it does*/
  backdrop-filter: blur(10px);
  opacity: 0;
  transition:
    opacity 150ms ease-in-out,
    backdrop-filter 150ms ease-in-out;
}

.visible {
  opacity: 1;
}

.exitDone {
  display: none;
}
