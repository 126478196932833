.form {
  display: flex;
  flex-grow: 1;
}

.input {
  color: inherit;
  border-color: inherit;
  border-right-width: 0;

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

.cta {
  composes: input;
  position: relative;
  width: var(--button-base);
  height: var(--button-base);
  background: transparent;
  border: 2px solid inherit;
  transition: all 0.15s;

  :focus ~ & {
    color: var(--mid-grey);
    border-color: var(--mid-grey);
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
