.center {
  display: flex;
  height: 100%;
}

.container {
  @media ($phone-down) {
    padding: spacing(2) spacing(0);
  }
}

.logo {
  @media ($phone-up) {
    display: none;
  }
}

.nav {
  & > * {
    display: block;
    margin-top: spacing(1);
  }

  @media ($phone-up) {
    margin-top: 0;
  }
}

.navItem {
  font-size: 2.5rem;
}
