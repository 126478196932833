.root {
  min-width: 200px;
  height: var(--button-base);
  padding: 0.75rem;
  font-family: var(--font-regular);
  font-size: 0.875rem;
  color: inherit;
  background: transparent;
  border: 2px solid var(--light-grey);
  transition: all 0.15s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &::placeholder {
    color: var(--mid-grey);
  }

  &:focus {
    outline: none;
  }

  &:focus:not([aria-invalid='true']) {
    border-color: var(--mid-grey);
  }

  &[aria-invalid='true']:focus {
    border-color: var(--light-grey);
  }

  &[aria-invalid='true'] {
    border-color: var(--error);
  }
}

.block {
  display: block;
  width: 100%;
}
