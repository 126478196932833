.layout {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: var(--off-white);
  transform-origin: 100% 50%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text {
  font-family: var(--font-regular);
  font-size: 1.125rem;
}

.button {
  composes: text;
  z-index: 1;
  font-size: 0.875rem;
  color: var(--off-black);
  background: none;

  & svg {
    width: 18px;
    height: 18px;
  }

  @media ($phone-down) {
    & svg {
      width: 23px;
      height: 23px;
    }
  }
}

.cancel {
  & svg {
    display: none;
  }

  @media ($desktop-down) {
    & svg {
      display: inline-block;
    }

    & p {
      display: none;
    }
  }
}

.toggle {
  position: relative;
  top: 0;
  right: 0;
  padding: spacing(2);
}

.inputContainer {
  position: relative;
  width: 674px;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: spacing(3);
  width: 18px;
  transform: translate(-50%, -50%);
}

.hideTabletPortraitDown {
  @media ($desktop-down) {
    display: none;
  }
}

.input {
  width: 100%;
  padding: spacing(1.5, 6);
  background: var(--light-grey);
  border: none;
  border-radius: 60px;

  &::placeholder {
    opacity: var(--disable-dim);
  }

  &:focus {
    outline: none;
  }

  @media ($desktop-down) {
    flex-grow: 1;
    width: 100%;
    padding: 0;
    background: transparent;
    border-radius: 0;
  }
}
