.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: 1.125rem;
  cursor: pointer;
}

.label {
  padding-left: 2em;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: var(--grey-yabba);

  .monochromatic & {
    color: var(--white);
  }
}

.input {
  position: absolute;
  opacity: 0;

  &:focus {
    & ~ .fauxCheckbox {
      outline: 2px solid var(--off-black);
    }

    &:not(:focus-visible) ~ .fauxCheckbox {
      outline: none;
    }
  }
}

.disabled {
  opacity: 0.5;
}

.fauxCheckbox {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  transform: translateY(-50%);

  [aria-disabled='true'] ~ & {
    cursor: not-allowed;
    opacity: 0.6;
  }

  :checked ~ & {
    background-color: var(--off-black);
    background-image: url('~icons/check-white.svg');
    background-position: center;
    background-size: 1rem;
  }
}
