.root {
  height: var(--button-base);
  padding-right: 40px;
  cursor: pointer;
}

.wrapper {
  position: relative;

  &::after {
    position: absolute;
    top: 55%;
    right: 0;
    width: 22px;
    height: 24px;
    pointer-events: none;
    content: '';
    /* background: url('~icons/expand_more.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.5;
    transform: translate(-50%, -50%);
  }
}
