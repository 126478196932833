.root {
  position: sticky;
  top: 0;
  z-index: var(--z-nav);
  color: var(--text-darker);
  background: var(--off-white);
}

.logo {
  flex-shrink: 0;
}

.content {
  display: flex;
  align-items: center;
  height: var(--nav-height);
}

.spacer {
  margin-left: auto;
}

.items {
  display: flex;
  width: 100%;
  padding: 0 1rem;
  overflow: hidden;
  /* 
  @media ($tablet-portrait-down) {
    display: none;
  } */

  &.hide {
    pointer-events: none;
    opacity: 0;
  }
}

.menuToggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: var(--nav-height);
}
