$size: 4rem;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: $size;

  & > * + * {
    margin-left: var(--gap-small);
  }
}

.image {
  width: $size;
  height: $size;
  object-fit: cover;
}

.text {
  line-height: 1.2em;
}

.close {
  width: $size;
  height: $size;
  margin-left: auto;
  background-color: transparent;
  background-image: url('~icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.25s;
  }
}
