.root {
  text-align: left;
  background-color: none;
}

.flip {
  display: flex;
  flex-direction: column-reverse;
}

.text {
  margin-top: 1rem;

  .flip & {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
