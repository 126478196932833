.root {
  width: 100%;
  padding: spacing(1, 0);
  font-size: 0.875em;
  text-align: center;

  @media ($tablet-portrait-up) {
    font-size: 1rem;
  }
}
