.highlight-container {
  @media ($tablet-landscape-up) {
    padding: 0 var(--side-margin);
  }
}

.exhibitLink {
  margin-top: 1rem;
}

.exhibitLinkArrow {
  height: 0.75rem;
  vertical-align: baseline;
  transform: translateY(1px);
}
