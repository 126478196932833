.root {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  padding: 40px 70px;
  background-color: var(--loading);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: translate(-50%, -50%);

  @media ($phone-down) {
    padding: 30px;
  }
}

.overlay {
  & > * {
    pointer-events: none;
  }
}

.resetLetterspacing {
  letter-spacing: -0.5px;
}

.button {
  display: flex;
  align-content: center;
  width: fit-content;
  padding: spacing(1.9) spacing(4);
  margin-top: spacing(3);

  &-text {
    line-height: 1;
  }

  /* Coloring options */
  &-none {
    color: transparent;
    background-color: transparent;
  }

  &-white {
    color: var(--text-light);
    background-color: var(--off-black);
  }

  &-black {
    color: var(--text-dark);
    background-color: var(--off-white);
  }

  /* Alignment options */
  &-left {
    margin-right: auto;
  }

  &-center {
    margin-right: auto;
    margin-left: auto;
  }

  &-right {
    margin-left: auto;
  }

  @media ($phone-down) {
    padding: spacing(1.5) spacing(2.5);
    margin-top: spacing(1.5);

    &-none {
      display: none;
    }
  }
}

.text {
  z-index: var(--z-over);
  display: flex;
  flex-direction: column;
  color: white;
  letter-spacing: -1.5px;
}

.textContainer {
  max-width: 500px;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
