.root {
  width: 100%;
  margin-top: auto;
}

.links {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ($phone-down) {
    flex-direction: column;

    & > * + * {
      margin-top: spacing(4);
    }
  }
}

.groups {
  display: flex;

  & > * + * {
    margin-left: spacing(10);
  }

  @media ($phone-down) {
    flex-direction: column;

    & > * + * {
      margin-top: spacing(4);
      margin-left: 0;
    }
  }
}

.group {
  display: flex;
  flex-direction: column;
}
