.root {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-top: auto;

  & > * + * {
    margin-top: 0.5rem;
  }
}
