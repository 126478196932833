.item {
  padding: 1rem;
}

.flush-left {
  padding-left: 0;
}

.flush-right {
  padding-right: 0;
}

.active {
  text-decoration: underline;
}
