.root {
  position: relative;

  @supports (display: grid) {
    display: grid;
  }
  grid-auto-rows: max-content;
}
/* stylelint-disable plugin/no-unused-selectors */
.gap-small {
  grid-gap: var(--gap-small);
}

.gap-regular {
  grid-gap: var(--gap-medium);
}

.gap-big {
  grid-gap: var(--gap-big);
}

.columns-2 {
  grid-template-columns: repeat(2, 1fr);

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);

  @media ($tablet-landscape-down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}

.columns-6 {
  grid-template-columns: repeat(6, 1fr);

  @media ($tablet-landscape-down) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ($tablet-portrait-down) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.responsive-columns {
  @media (min-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* stylelint-enable plugin/no-unused-selectors */
