.root {
  position: relative;
  overflow: hidden;
  background-color: var(--loading);

  /* this could probably be replaced with the aspect-ratio property */
  &::before {
    display: block;
    width: 100%;
    padding-top: calc(100% / var(--aspect-ratio, 1));
    content: '';
  }
}

.image {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.loaded {
  opacity: 1;
}
