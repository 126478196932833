.position {
  position: fixed;
  z-index: var(--z-dialog);
  overflow: scroll;

  &.left {
    top: 0;
    left: 0;
    height: 100%;
  }

  &.middle {
    top: 50%;
    left: 50%;
  }

  &.right {
    top: 0;
    right: 0;
    height: 100%;
  }

  &.bottom {
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: spacing(3, 5);
}

.middle {
  min-width: 420px;
  max-width: 450px;
  max-height: calc(100vh - var(--side-margin));
  transform: translate(-50%, -50%);

  @media ($phone-down) {
    width: 100%;
    min-width: unset;
    height: 100%;
    max-height: none;
  }
}

.right {
  width: 380px;
  height: 100%;

  @media ($phone-down) {
    width: 100%;
    height: 100%;
    max-height: none;
  }
}

.left {
  width: 380px;
  height: 100%;

  @media ($phone-down) {
    width: 100%;
    height: 100%;
    max-height: none;
  }
}

$size: 32px;

.btnClose {
  position: absolute;
  top: spacing(3);
  right: spacing(3);
  width: $size;
  height: $size;
  background: none;
  background-image: url('~icons/close.svg');
  background-position: center;
  background-size: $size;
}
