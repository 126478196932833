.root {
  display: block;
  width: min-content;

  &:focus-within .label {
    /* color: var(--offblack); */
    opacity: 1;
  }
}

.disabled {
  cursor: not-allowed;
}

.label {
  display: block;
  margin-bottom: 0.4rem;
  font-size: 0.875rem;
  color: var(--text-dark);
  text-transform: uppercase;
  transition: all 0.15s;

  .disabled & {
    opacity: 0.5;
  }
}

.block {
  width: 100%;
}

.error {
  /** no error-specific styles at the moment */
}
